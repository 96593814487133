export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3403530000430957',
  agentpwd : '3403@0957$',
  apikey : '7f35bd942922b3b472c661525d3fa6b1',
  url : 'https://style.afrikpay.com',
  id : 'style.afrikpay.com',
  accepturl : 'https://style.afrikpay.com/payment/web/success',
  cancelurl : 'https://style.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};